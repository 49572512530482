'use client';

import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';

function Login() {
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		setIsError(false);
		const password = e.target.password.value;
		const res = await fetch('/api/auth/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ password }),
		});

		if (res.status === 200) {
			window.location.href = '/generate';
		} else {
			setIsError(true);
		}
		setIsLoading(false);
	};

	return (
		<Stack alignItems='center' direction='column' py={6} spacing={4}>
			<Typography align='center' gutterBottom variant='h4'>
				This site is password protected{' '}
			</Typography>
			<form onSubmit={handleSubmit}>
				<Stack alignItems='center' direction='column' spacing={2}>
					<TextField
						autoComplete='current-password'
						error={isError}
						helperText={isError && 'Incorrect password'}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setShowPassword(!showPassword)}
									>
										{showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
									</IconButton>
								</InputAdornment>
							),
						}}
						label='Password'
						name='password'
						type={showPassword ? 'text' : 'password'}
						variant='outlined'
					/>
					<LoadingButton loading={isLoading} type='submit' variant='contained'>
						Login
					</LoadingButton>
				</Stack>
			</form>
		</Stack>
	);
}

export default Login;
